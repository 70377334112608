body {
  font-family: "Montserrat", sans-serif;
  /* font-family: "Raleway", sans-serif; */
}

.radio-container {
  display: block;
  margin-bottom: 15px; /* Add some margin between each radio option */
}

.radio-container input[type="radio"] {
  display: none;
}

.radio-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.radio-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #441890;
  border-radius: 50%;
}

.radio-container input[type="radio"]:checked + .radio-label:before {
  background-color: #fff; /* Change the background color to white */
}

.radio-container input[type="radio"]:checked + .radio-label:after {
  content: '';
  position: absolute;
  left: 4px; /* Adjust the position of the inner circle */
  top: 4px; /* Adjust the position of the inner circle */
  width: 12px; /* Increase the size of the inner circle */
  height: 12px; /* Increase the size of the inner circle */
  border-radius: 50%;
  background-color: #441890; /* Change the inner circle color */
}

.about-us-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  text-align: center;
}

.title {
  font-size: 54px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #441890;
}

.description-section {
  flex-grow: 1;
  background-color: #441890;
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-content {
  padding: 50px;
  color: #fff;
  text-align: center;
  margin: 20px auto; /* Center the element horizontally */
  max-width: 1100px; /* Set a maximum width */
}

.headline {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.description {
  font-size: 24px;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .description-section .description {
    font-size: 18px; /* Adjust the font size as needed */
  }
}

/* Added styles for side-by-side layout */
.description-pair {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.description-container {
  flex-basis: 45%; /* Adjust the width as needed */
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .description-container {
    flex-basis: 100%;
  }
}

.tag {
  display: inline-block;
  background-color: #e2e8f0;
  color: #4a5568;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem; /* Adjust as needed */
  margin-bottom: 0.5rem; /* Adjust as needed */
  border-radius: 999px; /* to make it round */
  white-space: nowrap; /* Prevent wrapping */
}
